<template>
  <div>
    <img src="@/assets/img/topBG.jpg" alt="" />
    <div class="bod">
      <van-field
        class="input"
        v-model="phone.account"
        type="number"
        maxlength="11"
        placeholder="请输入手机号码" />
      <van-field
        class="input"
        type="number"
        center
        v-model="login.code"
        maxlength="6"
        clearable
        placeholder="请输入短信验证码"
        use-button-slot>
        <span
          style="color: #5380ff"
          slot="button"
          @click="countDown"
          :disabled="disabled"
          >{{ content }}</span
        >
      </van-field>
      <van-popup v-model="show">
        <div class="v_popup">
          <div style="text-align: right">
            <van-icon name="cross" @click="cross" />
          </div>
          <p>安全验证</p>
          <div>
            <p
              style="
                text-align: center;
                font-size: 0.8rem;
                margin-bottom: 0.9rem;
              ">
              请滑动滑块至右边
            </p>
            <van-slider
              v-model="value"
              @change="onChange"
              active-color="#52C41A">
              <div slot="button" class="custom-button">
                <van-icon :name="icon" />
              </div>
            </van-slider>
            <span v-if="spanif" style="color: red">验证失败,请重新验证!</span>
            <!--             <span v-if="this.icon = 'checked' " style="color:#52C41A;">验证通过<van-icon style="color:#52C41A;" :name="icon"/></span>
             -->
          </div>
        </div>
      </van-popup>
      <button class="btn" @click="GO">立即登录</button>
      <div class="xeiy">
        绑定/登录即代表您同意<span @click="xeiyy()">《用户服务协议》</span>
      </div>
      <van-popup
        v-model="xeshow"
        closeable
        position="bottom"
        :style="{ height: '100%' }">
        <xeiy></xeiy>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { GetsmsCode, Login, auth, isBind } from "@/api/login";
import { Notify } from "vant";
import Cookies from "js-cookie";
import xeiy from "../../components/xeiy.vue";
import {
  setUserInfo,
  getUserInfo,
  getToken,
  setMobile,
  getMobile,
  removeUserInfo,
} from "../../utils/user.js";
import * as Status from "../../utils/status.js";

export default {
  components: { xeiy },
  data() {
    return {
      content: "发送验证码",
      totalTime: 60, // 按钮倒计时
      canClick: true, //添加canClick
      photonumber: "",
      value: 0, // 滑块进度
      show: false, // 弹出层显示隐藏
      icon: "arrow", // 滑块动态icon
      spanif: false,
      phone: {
        account: "",
      },
      login: {
        account: "",
        code: "",
        openid: "",
      },
      disabled: false,
      xeshow: false,
    };
  },
  created() {
    // setUserInfo(
    //   '{"openid":"o6RIy5_L3xfTwR-glhdF6E4IZ45Y","nickname":"W.ang","avatar":"https:\/\/thirdwx.qlogo.cn\/mmopen\/vi_32\/6bCYR5TvPPOX4uSL3JonKA0QYVly5gOCiblicH3o1pz5EdUoNtvoXkicJlnsHgRficfyOHRJ3LN9VuQ1znkicTm7mxQ\/132"}'
    // );
    var info = getUserInfo();
    if (info) {
      // console.log("10. isBind...");

      isBind({ openid: info["openid"] });

    } else {
      // setUserInfo('{"openid":"o6RIy5wCmHbjduZPw9bb0Aw-WJQw","nickname":"W.ang","avatar":"https:\/\/thirdwx.qlogo.cn\/mmopen\/vi_32\/6bCYR5TvPPOX4uSL3JonKA0QYVly5gOCiblicH3o1pz5EdUoNtvoXkicJlnsHgRficfyOHRJ3LN9VuQ1znkicTm7mxQ\/132"}')
      // console.log("2. getCode...");
      // this.$store.commit('setLog', "2. getCode...")

      this.getCode();
    }
  },
  methods: {
    getCode() {
      // 非静默授权，第一次有弹框
      var info = getUserInfo();
      if (!info) {
        this.code = "";
        var local = window.location.href;
        var appid = process.env.VUE_APP_APPID;

        let code1 = this.$route.query.code;
        // let code2 = this.getUrlCode();

        // this.$store.commit('setLog', 'code1: ' + code1)
        // this.$store.commit('setLog', 'code2: ' + code2.code)

        // console.log("3. code1: " + code1);
        // console.log("4. code2: " + code2.code);
        // this.$store.commit('setLog', "3. code1: " + code1)
        // this.$store.commit('setLog', "4. code2: " + code2.code)

        // this.code = code1 !== undefined ? code1 : code2.code;
        this.code = code1;
        if (this.code == null || this.code === "") {
          // console.log("5. local.href......");
          // this.$store.commit('setLog', '5. local.href......')

          // 如果没有code，则去请求
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
            local
          )}&response_type=code&scope=snsapi_userinfo&state=lanbi#wechat_redirect`;
        } else {
          // console.log("6. getUserInfo...");
          // this.$store.commit('setLog', "6. getUserInfo...")

          // console.log("7. auth request...");
          // this.$store.commit('setLog', "7. auth request...")

          auth({ code: this.code }).then((res) => {
            // console.log("8. res request..." + res.msg);
            // this.$store.commit('setLog', "8. res request..." + res.msg)

            if (res.code === Status.SUCCESS) {
              var json = res.data;
              var jsonStr = JSON.stringify(json);
              setUserInfo(jsonStr);
            } else {
              // console.log(this.$store.state.log)
              Notify({ type: "warning", message: res.msg, duration: 2000 });
            }
          });
        }
      }

      // console.log("11. created end...");
      // this.$store.commit('setLog', "11. created end...")
    },
    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      var theRequest = new Object();
      if (url.indexOf("?") !== -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    onChange(value) {
      if (value < 100) {
        this.value = 0; // 滑块不到头时，清零
        this.spanif = true;
      } else {
        this.spanif = false;
        this.icon = "checked"; // 滑动完成
        // 延时关闭弹窗
        setTimeout(() => {
          this.show = false;
        }, 500);
        // 验证码
        GetsmsCode(this.phone).then((res) => {
          if (res.code === Status.SUCCESS) {
            Notify({
              type: "success",
              message: "验证码已发送，请及时接收",
              duration: 2000,
            });
            // 倒计时
            this.value = 0;
            this.icon = "arrow";
            // 验证码按钮倒计时
            if (!this.canClick) return; //改动的是这两行代码
            this.canClick = false;
            this.content = this.totalTime + "s后重新发送";
            let clock = window.setInterval(() => {
              this.totalTime--;
              this.content = this.totalTime + "s后重新发送";
              this.disabled = true;
              if (this.totalTime < 0) {
                window.clearInterval(clock);
                this.content = "重新发送验证码";
                this.disabled = false;
                this.totalTime = 60;
                this.canClick = true; //这里重新开启
              }
            }, 1000);
          } else {
            this.show = false;
            Notify({ type: "warning", message: res.msg, duration: 2000 });
            this.value = 0;
            this.icon = "arrow";
          }
        });
      }
    },
    countDown() {
      if (this.phone.account === "") {
        Notify({ type: "warning", message: "请输入正确手机号" });
        return;
      } else if (this.phone.account.length < 11) {
        Notify({ type: "warning", message: "请输入正确手机号" });
        return;
      }
      this.show = true;
    },
    GO() {
      // this.login.code = this.login.code;
      this.login.account =
        this.phone.account; /* parseInt(this.phone.account) */
      if (this.login.code === "") {
        Notify({
          type: "warning",
          message: "请输入正确手机号或验证码",
          duration: 2000,
        });
      } else {
        var info = getUserInfo();
        // if (!info) {
        //   Notify({type: 'warning', message: '请先授权登录', duration: 2000});
        //   this.$router.push('/');
        // }
        this.login.openid = info.openid;
        Login(this.login)
          .then((res) => {
            if (res.code === Status.SUCCESS) {
              Notify({ type: "success", message: "验证成功", duration: 2000 });
              setMobile(this.login.account);
              var role = res.data;
              this.$store.commit("savePath", role); //存vuex
              this.$router.push("/Choose");
            }
            if (res.code === Status.ERROR) {
              Notify({
                type: "warning",
                message: "请输入正确的手机号或验证码",
                duration: 2000,
              });
            }
          })
          .catch(() => {
            Notify({ type: "warning", message: "登录失败", duration: 2000 });
          });
      }
    },
    cross() {
      this.show = false;
    },
    xeiyy() {
      this.xeshow = true
    },
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
}

.bod {
  margin-top: 2.75rem;
  width: 100%;
  text-align: center;
}

.input {
  width: 90%;
  background: #f6f8fa;
  height: 2.875rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  left: 5%;
}

.btn {
  width: 90%;
  height: 3rem;
  margin-top: 2rem;
  border: 0;
  border-radius: 2.25rem;
  color: white;
  background: linear-gradient(270deg, #2357f0 0%, #3db1ea 100%);
}

// 弹出层
//anniu
.custom-button {
  border: 2px solid #c1c1c1;
  background-color: white;
  width: 31px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.van-popup--center {
  width: 80%;
}

.v_popup {
  padding: 1rem;
}

.v_popup > p {
  font-weight: bold;
  font-size: 1.2rem;
}

// 滑块
.van-slider {
  margin-left: 50%;
  transform: translate(-50%);
  height: 35px;
  width: 80%;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.xeiy {
  font-size: 3vw;
  padding: 2vh 0 0 0;
  color: #999;

  span {
    color: #3db1ea;
  }
}
</style>
