<template>
    <div class="body">
        <p style="width: 100%;text-align: center;font-size: 4.5vw;">用户服务协议</p>
        <div>
            尊敬的用户，欢迎您注册并使用广州蓝笔信息科技有限公司<span>（以下称“蓝笔科技”）</span>提供的智慧校园平台系统服务（以下简称“本系统”）。<span>在此特别提醒：如您欲访问或使用本系统，必须事先认真阅读并遵守本《用户服务协议》（以下称“本协议”）。</span>
        </div>
        <div>
            <span>关于本协议，提示您特别关注限制、免责条款、违约行为的认定处理条款，以及管辖法院的选择条款等。限制、免责条款可能以加粗或加下划线形式提示您注意。您一经注册或使用本协议约定的服务即视您为对本协议全部条款已充分理解并完全接受，并且一经您注册或使用本协议约定的服务，本协议立即生效。</span>
        </div>
        <br>
        <div>
           <span> 一、协议内容和效力</span>
        </div>
        <div>
            1、本协议内容包括本协议正文及所有蓝笔科技<span>通过系统公告的方式已经予以公开发布或将来可能发布的各类规则制度、操作流程</span>、通知、警示、提示、说明（以下简称“规则”）。<span>前述规则均为本协议不可分割的一部分，与本协议具有同等的法律效力。</span>
        </div>
        <div>
            2、如本协议发生变更，蓝笔科技将通过本系统公告的方式提前予以公布，变更内容的生效时间以公告时间为准。<span>若您在本协议变更后继续使用本系统的，表示您接受变更后的协议，变更后的协议因您继续使用本系统的行为而生效，同时，视您充分理解并完全接受变更后的协议，并且将遵循变更后的协议使用本系统。</span>
        </div>
        <div>
            3、如您为无民事行为能力人（不满8周岁的未成年人或不能辨认自己行为的成年人）或为限制民事行为能力人（8周岁以上的未成年人或不能完全辨认自己行为的成年人），请在您监护人的指导下阅读本协议和使用本系统。若您是中华人民共和国行政管辖区域以外的用户，您还需同时遵守您所属国家或地区的法律，且您确认，订立并履行本协议不违反您所属、所居住或开展经营活动或其他业务的国家或地区的法律法规。
        </div>
        <br>
        <div>
            <span>二、服务内容</span>
        </div>
        <div>
            1、蓝笔科技目前提供电脑WEB端及移动端服务软件，将来可能不断丰富您使用本系统的终端、形式等，如您已注册使用一种形式的服务，则可以以同一账号使用其他版本服务，本协议自动适用于您对所有版本的软件和服务的使用。
        </div>
        <div>
            2、蓝笔科技系统服务，指【智慧校园平台】系统服务，是蓝笔科技已开发并可向用户提供的软件管理系统，包括但不限于蓝笔科技基于本系统功能完善而持续研发的产品、服务或其升级版本，但不包括软件管理系统的内容提供、管理服务和个性化定制。
        </div>
        <div>
            3、蓝笔科技保留在任何时候自行决定对服务或服务任何部分及其相关功能、应用软件变更、升级、修改、转移的权利。您同意，对于上述行为，蓝笔科技均不需通知，并且对您和任何第三人不承担任何责任。
        </div>
        <div>
            4、您可以申请本系统服务管理账号，通过审核后可通过该管理账号上传和管理成员通信录，邀请成员加入，并通过本系统实现内外部成员沟通、工作互动等目的。
        </div>
        <div>
            5、我司提供的服务包括但不限于：家校视频通话或语音留言、实时接收学生考勤情况、查看学生综合素质评价报告、全学科线上学习资源、考试易错点及薄弱点总结分析、智能私教服务、心理测评报告等，具体以平台提供的服务项目为准。
        </div>
        <div>
            6、您使用我司提供的服务功能时，会使用到您上传的或输入的个人等相关信息，<span>请您确保，您已获得个人及资产所有权人的完整的授权同意，未获得授权同意而调用相关功能获取信息引起的纠纷由您来承担法律责任</span>。上述相关信息仅供向第三方机构进行查询并向您做出反馈。
        </div>
        <br>
        <div>
            <span>三、服务费用</span>
        </div>
        <div>1、本系统的任何免费试用或免费功能和服务不应视为蓝笔科技放弃后续收费的权利。蓝笔科技有权提前以公告形式通知您收费标准，若您继续使用则需按蓝笔科技公布的收费标准支付费用。</div>
        <div>
            2、所有费用需通过蓝笔科技接受的支付方式进行支付，包括但不限于微信支付等第三方途径收取，具体以实际公告通知的支付方式为准。若您未按官方公告通知的方式支付所产生的额外损失，将由您自行承担。
        </div>
        <div>
            3、蓝笔科技有权根据实际情况提前单方调整费用标准及收费方式，并以公告形式通知您，而无需获得您的事先同意，如您不同意收费应当立即停止本系统收费服务的使用，否则使用则视为您已同意并应当缴纳费用。
        </div>
        <div>
            4、如果您没有在到期前续费和/或免费试用期届满前支付费用，届时蓝笔科技有权停止向您提供本系统收费服务。
        </div>
        <div>
            5、我司提供的系统服务含基础免费服务与增值收费服务，本系统收费价格为系统对应的网络虚拟服务价格，而非预付款或者存款、定金等性质。系统增值服务费用均为用户自愿购买，如您拟终止增值服务的，可以在购买增值服务到期后不再续费，已经开通的增值服务价格不支持退费（因增值服务存在重大瑕疵导致您完全无法使用等系统违约情形、蓝笔科技系统及本协议另有约定、法律法规要求必须退款或者蓝笔科技同意退款等除外）。
        </div>
        <div>
            6、收费规则及收费详情根据您所选择的服务内容及服务方式相关，其中部分服务由平台与合作学校联合提供，具体以我司与合作学校实际签订的协议或平台发布的收费通知为准。
        </div>
        <div>
            7、如您向本系统支付了平台服务费用，则视为了解并同意接受本系统服务及本协议全部内容。
        </div>
        <div>
            8、蓝笔科技仅提供【51xue.me】公布的本系统相关服务，除此之外与服务有关的设备（如手机等与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由您自行承担，您应自行购买和使用正版软件接受网络服务。
        </div>
        <br>
        <div>
            <span>四、注册及账号管理</span>
        </div>
        <div>
            为有效保障您使用本系统时的合法权益，提醒您注意以下事项：
        </div>
        <div>
            <span>（一）身份验证</span>
        </div>
        <div>
            <span>1、身份要素</span>：指本系统用于识别用户身份的信息要素，包括但不限于用户的账户、密码、人脸照片、短信校验码、电话号码、手机号码、微信号以及身份证件号码等。
        </div>
        <div>
            2、您在注册、使用本系统服务的过程中，应提供合法、真实、有效、准确、完整的资料（包括但不限于姓名、身份证等证明、联系方式、从事职业、通讯地址等）。为了能将本系统的信息变动及时通知到您，以及更好保障您的账户安全，如该等资料发生变更，请您及时通知蓝笔科技。<span>为了及时有效地验证您的信息（包括但不限于身份信息、账户信息）和持续、便捷的为您提供服务，根据法律法规及监管规定或蓝笔科技认为有需要时，您同意蓝笔科技可以把您的信息提供给第三方，也同意第三方可以把您的信息提供给蓝笔科技，以便蓝笔科技进行验证。您应确保账户绑定的电子邮箱、微信号或手机号均为您本人持有，如您使用了他人的电子邮箱、微信号或手机号的，为避免给电子邮箱、微信号或手机号持有人带来不便或不利影响，也为了您的账户及资产安全，本系统可能将该邮箱、微信号或手机号从您的会员号或账户中删除并解除关联。</span>
        </div>
        <div>
            <span>3、为了满足相关监管规定的要求，您同意按照蓝笔科技的要求，在通知的时间内提供您的身份信息以完成身份验证，否则您可能无法进行本系统操作，且蓝笔科技将对您的账户进行冻结或注销，因此产生的损失由您自行承担。</span>
        </div>
        <div>
            <span>（二）注意事项</span>
        </div>
        <div>
            1、您认可的使用记录、交易数据等均以本系统记录为准。如您对该等数据存有异议的，可及时向蓝笔科技提出异议，并提供相关证据供蓝笔科技核实。
        </div>
        <div>
            2、蓝笔科技在您使用业务申请等服务过程中，可根据有关文件、协议和本系统的相关规则、说明等收取必要的服务和管理费用，其具体内容、比例、金额等事项请参见有关文件及本系统相关页面的规则和说明。<span>您同意，蓝笔科技有权不定期调整前述服务或管理费用的类型或金额等具体事项，并根据本协议和相关规则进行公告、修改。</span>
        </div>
        <div>
            <span>3、身份要素是本系统识别您身份的依据，请您务必妥善保管。使用身份要素进行的任何操作、发出的任何指令均视为您本人做出。因您的原因造成的账户、密码等信息被冒用、盗用或非法使用，由此引起的一切风险、责任、损失、费用等由您自行承担。您同意：</span>
        </div>
        <div>
            （1）基于不同的终端以及您的使用习惯，蓝笔科技可能采取不同的验证措施识别您的身份。例如您的账户在新设备首次登录的，蓝笔科技可能通过密码加校验码的方式识别您的身份。
        </div>
        <div>
            <span>（2）本系统账户仅限您本人使用，不可转让、借用、赠与、继承，但本系统内的相关财产权利义务可被依法继承，法律另有规定的除外。</span>
        </div>
        <div>
            <span>（3）基于运行和安全的需要，蓝笔科技可以暂停或者限制本系统服务部分功能，或增加新的功能。</span>
        </div>
        <div>
            <span>（4）在密码或账户遭到未获授权的使用，或者发生其他任何安全问题时，将立即通知蓝笔科技，且您同意并确认，蓝笔科技不对上述情形产生的任何直接或间接的遗失或损害承担责任。</span>
        </div>
        <div>
            <span>4、蓝笔科技根据本协议收回或取消账号后，有权自行对账号相关内容及信息以包括但不限于删除等方式进行处理，且无需就此向用户承担任何责任。</span>
        </div>
        <br>
        <div>
            <span>五、使用规则</span>
        </div>
        <div>
            1、您注册本系统服务账号，制作、发布、传播信息内容的，应当使用真实身份信息，不得以虚假、冒用的居民身份信息、企业注册信息、组织机构代码信息进行注册。
        </div>
        <div>
            2、如您违反前述1条之约定，依据相关法律、法规及国家政策要求，蓝笔科技有权随时中止或终止您对服务的使用且不承担责任。
        </div>
        <div>
            3、您在使用本系统服务时，蓝笔科技有权基于安全运营、社会公共安全的需要或国家政策的要求，要求您提供准确的个人资料，如您提供的资料信息有任何变动，导致您的实际情况与您提交给蓝笔科技的信息不一致的，您应及时更新。
        </div>
        <div>
            4、您不应将其账号、密码转让或出借予他人使用。如您发现账号或服务遭他人非法使用，应立即通知蓝笔科技协调处理。因无法对非法或未经授权使用您账号及密码的行为作出甄别，蓝笔科技将不承担任何责任。
        </div>
        <div>
            5、您同意蓝笔科技在提供服务过程中以各种方式投放各种商业性广告或其他任何类型的商业信息，并且您同意接受蓝笔科技通过电子邮件或其他方式向您发送商品促销或其他相关商业信息。
        </div>
        <div>
            6、对于您通过本系统公开发布的任何内容，您同意蓝笔科技在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。
        </div>
        <div>
            7、您在使用本系统服务过程中，不得在网站上制作、复制、上传、发布、传播含有下列内容之一的信息：
        </div>
        <div>（1）违反宪法确定的基本原则的；</div>
        <div>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</div>
        <div>（3）损害国家荣誉和利益的；</div>
        <div>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</div>
        <div>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
        <div>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
        <div>（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</div>
        <div>（8）侮辱或者诽谤他人，侵害他人合法权益的；</div>
        <div>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</div>
        <div>（10）以非法民间组织名义活动的；</div>
        <div>（11）不符合《即时通信工具公众信息服务发展管理暂行规定》及遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的；</div>
        <div>（12）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</div>
        <div>（13）涉及他人隐私、个人信息或资料的；</div>
        <div>（14）发表、传送、传播骚扰、广告信息、过度营销信息及垃圾信息或含有任何性或性暗示的；</div>
        <div>（15）干扰本系统正常运营和侵犯其他您或第三方合法权益内容的信息。</div>
        <div>（16）含有中国法律、法规以及任何具有法律效力之规范所限制或禁止的其它内容的。</div>
        <div>8、您承诺自己在使用本系统服务的所有行为均遵守国家法律、法规和本网站的相关规定以及各种社会公共利益或公共道德。不得将本系统用于任何非法目的（包括用于反洗钱法律法规所禁止和限制的交易），也不得以任何非法方式使用本系统。对于任何法律后果的发生，您将以自己的名义独立承担所有相应的法律责任，蓝笔科技不承担任何责任。</div>
        <div>9、您在使用本系统服务时如发现本系统或其他相关系统的信息安全漏洞需及时通知蓝笔科技，禁止对本系统进行漏洞扫描、渗透测试等可能造成平台损失的行为。</div>
        <div>10、除非法律允许或蓝笔科技书面许可，您使用本系统服务过程中不得从事下列行为，一经发现您存在下列行为，蓝笔科技将冻结您账户甚至注销您账户，如对蓝笔科技造成损失的，蓝笔科技有权就全部损失对您进行追偿：</div>
        <div>（1）删除本系统及其副本上关于著作权的信息；</div>
        <div>（2）对本系统和程序进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本系统的源代码；不得对上述系统和程序（包括但不限于源程序、目标程序、技术文档、客户端至服务器端的数据、服务器数据）进行复制、修改、编译、整合或篡改，不得修改或增减本系统的任何功能。</div>
        <div>（3）对蓝笔科技拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</div>
        <div>（4）对本系统或者本系统运行过程中释放到任何终端内存中的数据、产品运行过程中客户端与服务器端的交互数据，以及本系统运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经蓝笔科技授权的第三方工具/服务接入本系统和相关系统；</div>
        <div>（5）通过修改或伪造产品运行中的指令、数据，增加、删减、变动产品的功能或运行效果，或者将用于上述用途的产品、方法进行运营或向公众传播，无论这些行为是否为商业目的；</div>
        <div>（6）自行或者授权他人、第三方产品对本系统及其组件、模块、数据进行干扰；</div>
        <div>（7）其他未经蓝笔科技明示授权的行为。</div>
        <div>11、您使用本系统服务过程中不得从事侵害他人合法权益之行为或违反国家法律法规，否则蓝笔科技有权进行调查、延迟或拒绝结算或停止提供服务，且您应独立承担所有相关法律责任，因此导致蓝笔科技或蓝笔科技雇员或其他方受损的，您应承担相应的赔偿责任。</div>
        <div>上述行为包括但不限于：</div>
        <div>（1）侵害他人名誉权、隐私权、商业秘密、商标权、著作权、专利权等合法权益。</div>
        <div>（2）违反依法定或约定之保密义务。</div>
        <div>（3）冒用他人名义使用服务。</div>
        <div>（4）从事不法交易行为，如洗钱、恐怖融资、贩卖枪支、毒品、禁药、盗版软件、黄色淫秽物品、其他蓝笔科技认为不得使用本系统进行交易的行为等。</div>
        <div>（5）提供赌博资讯或以任何方式引诱他人参与赌博。</div>
        <div>（6）未经授权使用他人银行卡，或利用信用卡套取现金。</div>
        <div>（7）进行与您或交易对方宣称的交易内容不符的交易，或不真实的交易。</div>
        <div>（8）从事任何可能侵害蓝笔科技服务系统、数据之行为。</div>
        <div>（9）其他违反法律法规规定、侵犯其他您合法权益、干扰产品正常运营或蓝笔科技未明示授权的行为。</div>
        <div>11、如果蓝笔科技发现或收到他人举报您有违反本协议约定的，蓝笔科技有权不经相关内容进行删除、屏蔽，并采取包括但不限于收回账号，限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。</div>
        <div>12、您在本平台上使用的能够获取反馈结果（包括不限于文字、图表、表格、数据等）的功能模块时，相关反馈结果不得用于修改、传播、转让或出售，不得利用此侵犯他人的相关利益。</div>
        <br>
        <div>
            <span>六、隐私政策</span>
        </div>
        <div><span>（一）信息收集使用规则</span></div>
        <div><span>蓝笔科技通过如下途径收集用户信息：</span></div>
        <div><span>1、您提供的信息。例如：</span></div>
        <div>(1)您注册、身份验证或使用本系统服务的过程中向蓝笔科技提供的身份要素等信息；</div>
        <div>(1)您注册、身份验证或使用本系统服务的过程中向蓝笔科技提供的身份要素等信息；</div>
        <div><span>2、蓝笔科技获取的信息。蓝笔科技可能从公开或您授权的资料中收集用户的信息数据，以更好地了解使用服务的用户，以确保在本系统进行交易的安全性。蓝笔科技仅收集蓝笔科技认为就此目的及达成该目的所必需的信息数据。</span></div>
        <div><span>3、其他方分享的信息。即其他方使用本服务时所提供的关于您的信息。</span></div>
        <div><span>（二）收集使用信息的目的、方式和范围</span></div>
        <div><span>1、您知晓并同意，蓝笔科技为提供本协议项下的服务，有权将您的身份要素、业务信息等向您选择的业务交易相对方或必要第三方提供。蓝笔科技承诺不会将您的身份信息在服务之外的目的使用。</span></div>
        <div>
            <span>2、您知晓并同意蓝笔科技有义务根据有关法律要求向司法机关和政府部门提供您的个人信息。在您未能按照与本协议、蓝笔科技有关规则或者与本系统其他第三方签订的有关协议的约定履行义务时，蓝笔科技有权根据自己的判断、有关协议和规则、国家生效法律裁决文书披露用户信息（包括但不限于在本系统公布用户的违法、违约行为，及将该内容计入任何与用户相关的信用资料、档案或数据库），蓝笔科技对此不承担任何责任。</span>
        </div>
        <div>3、如您通过签署协议等方式获得他人的个人信息，您同意并承诺不恶意使用他人信息或将他人个人信息用于任何非法目的。</div>
        <div>4、为达到服务用户的目的，在不透露单个用户隐私资料的前提下，我们有权利对整个用户数据库进行分析并对用户数据库进行商业上的利用。</div>
        <div>（三）联系方式</div>
        <div>您如需删除、更正其信息或有关于网络信息安全的投诉和举报，或者您对本隐私政策相关事宜有任何问题、意见或建议，请通过官方客服电话400-0979-021与蓝笔科技联系。</div>
        <div>一般情况下，蓝笔科技将在收到您发送的问题、意见或建议，并验证您身份信息后十五个工作日予以回复。</div><br>
        <div><span>七、终止服务</span></div>
        <div><span>蓝笔科技有权基于单方独立判断，在认为可能发生危害安全等情形时，不经通知而现行暂停、中断或终止向您提供本协议项下的全部或部分服务，甚至将注册资料封存、移除或删除，且无需向您或其他第三方承担任何责任。前述情形包括但不限于：</span></div>
        <div>1、蓝笔科技认为您提供的资料不具有真实性、有效性或完整性；</div>
        <div>2、蓝笔科技发现异常交易或有异议或有违法嫌疑时；</div>
        <div>3、蓝笔科技认为您使用本系统涉嫌洗钱、套取现金、传销、恶意使用他人身份信息或使用他人身份信息参与违法活动等情形；</div>
        <div>4、蓝笔科技认为您已经违反本协议中规定的各类规则及精神；</div>
        <div>5、您在使用收费服务时未按规定向蓝笔科技支付相应的费用；</div>
        <div>6、符合蓝笔科技《用户协议》中规定的情形；</div>
        <div>7、蓝笔科技有理由认为违反国家法律或侵害蓝笔科技或他人利益的情形。</div>
        <br>
        <div><span>八、有限责任</span></div>
        <div><span>1、本系统服务将按"现状"和按"可得到"的状态提供。蓝笔科技在此明确声明对本系统服务不作任何明示或暗示的保证，包括但不限于对服务的可适用性，没有错误或疏漏，持续性，准确性，可靠性，适用于某一特定用途之类的保证，声明或承诺。</span></div>
        <div><span>2、蓝笔科技对服务所涉的技术和信息的有效性，准确性，正确性，可靠性，质量，稳定，完整和及时性均不作承诺和保证。</span></div>
        <div><span>3、不论在何种情况下，蓝笔科技均不对由于Internet连接故障，电脑，通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。</span></div>
        <div><span>4、不论是否可以预见，不论是源于何种形式的行为，蓝笔科技不对由以下原因造成的任何特别的，直接的，间接的，惩罚性的，突发性的或有因果关系的损害或其他任何损害（包括但不限于利润或利息的损失，营业中止，资料灭失）承担责任。</span></div>
        <div><span>（1）使用或不能使用服务；</span></div>
        <div><span>（2）通过服务购买或获取任何产品，样品，数据，信息或进行交易等，或其他可替代上述行为的行为而产生的费用；</span></div>
        <div><span>（3）未经授权的存取或修改数据或数据的传输；</span></div>
        <div><span>（4）第三方通过服务所作的陈述或行为；</span></div>
        <div><span>（5）其它与服务相关事件，包括疏忽等，所造成的损害。</span></div>
        <div><span>5、您充分了解并同意，鉴于互联网体制及环境的特殊性，您在服务中分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途；您在此已充分意识此类风险的存在，并确认此等风险应完全由您自行承担，蓝笔科技对此不承担任何责任。</span></div>
        <div><span>6、您了解并同意，在使用服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）及匿名或冒名的信息的风险，基于服务使用规范所述，该等风险应由您自行承担，蓝笔科技对此不承担任何责任。</span></div>
        <div><span>7、蓝笔科技可能同时为您及您的（交易）对手方提供服务，您同意对蓝笔科技可能存在的该等行为予以明确豁免任何实际或潜在的利益冲突，并不得以此来主张蓝笔科技在提供服务时存在法律上的瑕疵。</span></div>
        <div>8、为了维护系统的正常运行，我们定期或不定期地对系统服务功能进行优化升级、对运行的相关设备进行维护或者检修，服务在合理时间内的中断，我们不承担责任。我们将在合理时间段内通过我们平台公告维护时间。</div>
        <div>9、在任何情况下，我们不声明及保证只要提供正确资料，就一定能通过我们平台获取正确的结论或建议。</div>
        <div>10、我们不对您使用平台服务得出的结论和建议的正确性、适当性、完整性、准确性、可靠性或适时性做出任何证明、声明和保证，相关结论和建议仅供参考，您依据此结论和建议作出的判断或决策由您自行承担，与平台无关，平台不为此承担任何责任和义务。</div>
        <div>11、平台对因第三方的过错或延误而给您造成的损失不承担责任。</div>
        <br>
        <div><span>九、知识产权的保护</span></div>
        <div>1、蓝笔科技在本系统及相应网站上所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由蓝笔科技依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。</div>
        <div>2、非经蓝笔科技或蓝笔科技关联公司书面同意，任何人不得擅自使用、修改、反向编译、复制、公开传播、改变、散布、发行或公开发表本网站程序或内容。</div>
        <div>3、尊重知识产权是您应尽的义务，如有违反，您应承担损害赔偿责任。</div>
        <br>
        <div><span>十、法律适用与管辖</span></div>
        <div>本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生之争议，均应依照中华人民共和国法律予以处理，并由广州市天河区人民法院管辖。</div>
        <br>
        <br>
        <div style="text-align: right;">广州蓝笔信息科技有限公司</div>
        <div style="text-align: right;">2023年11月27日</div>
        <br>
        <br>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
    .body{
        margin-top: 11%;
        height: 93%;
        overflow: auto;
        width: 100%;
        word-wrap: break-word; 
        word-break: normal; 
        padding: 0 3vw 0vh 3vw;
        box-sizing: border-box;
        text-align: left;
        
        :not(:first){
            color: red;
        }
        div{
            text-indent: 1cm;
            line-height: 3.5vh;
        }
        span{
            color: black;
            font-weight: bold;
        }
    }
</style>